<template>
	<div class="join-us">
		<BannerOther :img_url="img_url" :chinese_name='chinese_name' :english_name="english_name"></BannerOther>
		<div class="col-main-box">
			<Sidder :tab_selectIndex='tab_selectIndex'></Sidder>
			<div class="col-r-box">
				<!-- 导航栏 -->
				<div class="crumbs">
					<div class="cru-logo">
						<img src="../../assets/images/home/daohang_logo.png" alt="">
					</div>
					<el-breadcrumb separator=">">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item>加入我们</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="all-card">
					<div class="left-card">
						<div class="card-title">
							<div class="line"></div>
							<span>加入我们</span>
						</div>
						<div class="card-list-box card-list-box1">
							<span class="card-list-name">加入类型：</span>
							<div class="join-type">
								<div class="join-type-item" v-for="(item,index) in AllJoinArr" :key="index"
								:class="selectjoin == index ? 'type-item-active':''"
								@click="changejointype(index,item)">{{item}}</div>
							</div>
						</div>
						<div class="heng-box">
							<div class="card-list-box2">
								<span class="card-list-name">加入类型：</span>
								<div class="join-type">
									<div class="join-type-item" v-for="(item,index) in AllJoinArr" :key="index"
									:class="selectjoin == index ? 'type-item-active':''"
									@click="changejointype(index,item)">{{item}}</div>
								</div>
							</div>
						</div>
						
						<div class="card-list-box">
							<span class="card-list-name">姓名：</span>
							<el-input type="text" v-model="user_name" placeholder="请输入您的姓名" class="card-input" />
						</div>
						<div class="card-list-box">
							<span class="card-list-name">手机号：</span>
							<el-input type="text" v-model="user_phone" placeholder="请输入您的手机号" class="card-input" />
						</div>
						<div class="card-btn" @click="sumitMessage">提交留言</div>
					</div>
					<div class="right-logo">
						<img src="../../assets/images/join-us/logo.png" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import BannerOther from '@/components/banner-other/index.vue'
	import Sidder from '@/components/sidder/index.vue'
	import {
		jiaruList
	} from "@/api/joggle.js"
	export default {
		name:'Join-Us',
		components: {
			BannerOther,
			Sidder
		},
		data() {
			return {
				tab_selectIndex: 11,
				img_url: '',
				chinese_name: '加入我们',
				english_name: 'COLLEGE OF IMAGEING',
				AllJoinArr: ['摄影师', '摄像师','视频剪辑','摄影后期'],
				selectjoin:0,
				type:1, // 加入类型 1.摄影师 2.摄像师 3.视频剪辑 4.摄影后期
				user_name:'', // 姓名
				user_phone:'', // 手机号
			}
		},
		activated() {
			var that = this
			// 获取头图(栏目图)
			var company_info = JSON.parse(localStorage.getItem('company_info'));
			if(company_info){
				that.img_url = company_info.join_image;
			}
			that.options = JSON.parse(localStorage.getItem('options'));
			that.options.forEach((item,index)=>{
				if(item.name == that.chinese_name){
					that.tab_selectIndex = index
				}
			})
		},
		methods: {
			// 提交留言
			sumitMessage(){
				var that = this
				var obj = {
					type:that.type,
					name:that.user_name,
					phone:that.user_phone
				}
				jiaruList(obj).then(res => {
					if(res.code == 1){
						that.$message.success(res.msg);
						that.selectjoin = 0;
						that.type = 1;
						that.user_name = '';
						that.user_phone = '';
					}else{
						that.$message.error(res.msg);
					}
					
				})
			},
			// 选择加入类型
			changejointype(index,name){
				var that = this
				that.selectjoin = index;
				if(name == '摄影师'){
					that.type = 1;
				}
				if(name == '摄像师'){
					that.type = 2;
				}
				if(name == '视频剪辑'){
					that.type = 3;
				}
				if(name == '摄影后期'){
					that.type = 4;
				}
			}
		}
	}
</script>

<style lang="scss">
	@import '../../assets/css/public.scss';
	@import '../../assets/css/join-us.scss';
</style>
